import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import GatsbyImg from 'gatsby-image'

import styles from './venue.module.css'

import { useIsMobile } from '../hooks/screen-size'
import { useScroll } from '../hooks/scroll'
import Image from '../components/image'
import Carousel from '../components/carousel'
import CoverImage from '../components/cover-image'
import Promotions from '../components/promotions'
import RelatedEvents from '../components/related-events'
import Modal from '../components/modal'
import VenueHireRequest from '../components/venue-hire-request'
import MobileNavigator, { NavigatorItem } from '../components/mobile-navigator'
import Icon from '../components/icon'
import SEO from '../components/seo'


const carouselSlides = (coverImages, title, onPlay, onPause) =>
  (coverImages || []).map(i => {
    const imageSrc =
      // Only need to check either mobileCover or desktopCover
      // since they come from the same source
      i.image && i.image.childImageSharp ? i.image.childImageSharp.fluid : null

    return (
      <CoverImage
        className={styles.coverImage}
        videoUrl={i.youtube}
        image={imageSrc}
        title={title}
        onTrailerPlay={onPlay}
        onTrailerPause={onPause}
      />
    )
  })

const DetailsAndAmenities = ({ amenities }) =>
  amenities && amenities.length ? (
    <>
      <h2 className={`${styles.amenitiesHeader} text-center`}>
        Details and Amenities
      </h2>
      <div
        className={`${
          amenities.length < 6 ? 'justify-center' : ''
        } flex flex-row flex-wrap`}
      >
        {amenities.map((a, i) => (
          <div key={i} className="text-center p-6 w-1/2 md:w-1/4 lg:w-1/6">
            {a.image && <Icon icon={a.image} className={styles.amenityImage} />}
            <p className={styles.amenityLabel}>{a.label}</p>
          </div>
        ))}
      </div>
    </>
  ) : (
    ''
  )

const VenueMosaic = ({ image1, image2, image3, image4, image5 }) => (
  <>
    <div className={`${styles.mosaicWrapper} mb-32`}>
      <div className={styles.mosaicMarginY}>
        {image5 && <Image fluid={image5.childImageSharp.fluid} />}
      </div>
      <div className={`${styles.mosaicMarginY} flex flex-row`}>
        <div className={`${styles.mosaicPaddingRight} flex-1`}>
          {image3 && <Image fluid={image3.childImageSharp.fluid} />}
        </div>
        <div className={`${styles.mosaicPaddingLeft} flex-1`}>
          <div className={styles.mosaicPaddingBottom}>
            {image1 && <Image fluid={image1.childImageSharp.fluid} />}
          </div>
          <div className={styles.mosaicPaddingTop}>
            {image2 && <Image fluid={image2.childImageSharp.fluid} />}
          </div>
        </div>
      </div>
      <div className={styles.mosaicMarginY}>
        {image4 && <Image fluid={image4.childImageSharp.fluid} />}
      </div>
    </div>
    <div className="hidden w-full md:flex flex-row items-stretch justify-center mb-32">
      <div
        className={`${styles.mosaicMarginX} ${styles.mosaicFirstCol} flex flex-col`}
      >
        <div className={`${styles.mosaicMarginY} h-2/3`}>
          {image1 && <Image fluid={image1.childImageSharp.fluid} />}
        </div>
        <div className={`${styles.mosaicMarginY} h-1/3`}>
          {image2 && <Image fluid={image2.childImageSharp.fluid} />}
        </div>
      </div>
      <div
        className={`${styles.mosaicMarginX} ${styles.mosaicSecondCol} flex flex-col`}
      >
        <div className={`${styles.mosaicMarginY} h-full`}>
          {image3 && <Image fluid={image3.childImageSharp.fluid} />}
        </div>
      </div>
      <div
        className={`${styles.mosaicMarginX} ${styles.mosaicThirdCol} flex flex-col`}
      >
        <div className={`${styles.mosaicMarginY} h-1/3`}>
          {image4 && <Image fluid={image4.childImageSharp.fluid} />}
        </div>
        <div className={`${styles.mosaicMarginY} h-2/3`}>
          {image5 && <Image fluid={image5.childImageSharp.fluid} />}
        </div>
      </div>
    </div>
  </>
)

const Floorplan = ({ isMobile, title, floorplan }) => {
  const [isOpened, setIsOpened] = useState(false)

  if (!floorplan) return ''

  return (
    <div className="w-full mb-32 text-center">
      <h2 className={`${styles.floorplanHeader} text-center`}>Floorplan</h2>
      <div className="w-full">
        <Image image={floorplan} width="100%" height="" />
      </div>
      <button
        className="inline-block btn-primary mt-24 md:hidden"
        onClick={() => setIsOpened(true)}
      >
        Full Screen View
      </button>
      <Modal open={isOpened} handleClose={() => setIsOpened(false)}>
        <div className="h-full pt-10 flex flex-col items-center">
          <h2 className={`${styles.floorplanModalTitle} flex-initial mb-10`}>
            {title}
          </h2>
          <div className="overflow-auto flex-1 w-full">
            {floorplan.extension === 'svg' ? (
              <Image className="px-10" image={floorplan} />
            ) : (
              <GatsbyImg
                className="h-full w-full mx-10"
                fixed={floorplan.childImageSharp.fixed}
                objectFit="none"
                objectPosition="0% 0%"
                alt={title}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export const VenueTemplate = ({ venue, location }) => {
  const [isSlidePaused, setIsSlidePaused] = useState(false)
  const onVideoPlay = useRef()
  const onVideoPause = useRef()
  const isMobile = useIsMobile()
  const { scrollY } = useScroll()

  const amenitiesRef = useRef()
  const descriptionRef = useRef()
  const galleryRef = useRef()

  onVideoPlay.current = () => {
    setIsSlidePaused(true)
  }

  onVideoPause.current = () => {
    setIsSlidePaused(false)
  }

  const openRequestForm = useRef(() => {
    window.location = '#request'
  })

  const slides = carouselSlides(
    venue.carousel,
    venue.title,
    onVideoPlay.current,
    onVideoPause.current
  )

  const showNavigator =
    scrollY > (amenitiesRef.current && amenitiesRef.current.offsetTop - 300) &&
    scrollY <
      (galleryRef.current &&
        galleryRef.current.offsetTop + galleryRef.current.offsetHeight - 120)

  return (
    <>
     <SEO 
      title={venue.meta_title} 
      description={venue.meta_description}
    />
    <div className="container flex flex-col py-5 lg:py-20 items-center">
      <div className={`${styles.venueHeader} text-center`}>
        <div className={styles.venueSubtitle}>
          {(venue.descriptors || []).length
            ? venue.descriptors.map((d, i) => (
                <span key={i} className={styles.descriptor}>
                  {d}
                </span>
              ))
            : null}
        </div>
        <h1>{venue.title}</h1>
      </div>
      {venue.carousel && venue.carousel.length ? (
        <div className={styles.carouselWrapper}>
          <Carousel
            slides={slides}
            previewSlides={isMobile}
            isPaused={isSlidePaused}
          />
        </div>
      ) : null}
      <div className="flex flex-col items-center mb-20">
        <button
          className="btn-primary inline-block"
          onClick={openRequestForm.current}
        >
          Hire This Venue
        </button>
      </div>
      <div ref={amenitiesRef}>
        <DetailsAndAmenities amenities={venue.amenities} />
      </div>
      <div className="w-full" ref={descriptionRef}>
        <Promotions
          className={`${styles.venuePromotions} text-center md:text-left`}
          promotions={[{ coverImage: venue.image, bodyHtml: venue.bodyHtml }]}
        />
      </div>
      <div ref={galleryRef}>
        <VenueMosaic {...venue.mosaic} />
      </div>
      <Floorplan
        floorplan={venue.floorplan}
        isMobile={isMobile}
        title={venue.title}
      />
      <div className="w-full md:w-4/5 lg:w-3/5 xl:w-1/2 text-center mb-20">
        <h2 className={`${styles.bookingHeader} mb-6`}>
          Want to Book This Space?
        </h2>
        <p className="mb-10">
          Fill out the form (through the link below) and we will come back to
          you. Special rates available for recurring bookings
        </p>
        <div className="flex flex-col items-center mb-20">
          <button
            className="btn-primary inline-block"
            onClick={openRequestForm.current}
          >
            Hire This Venue
          </button>
        </div>
      </div>
      <div className="w-full lg:w-auto mb-10">
        <h2 className={`${styles.otherTitle} text-center mb-10`}>
          Other Spaces
        </h2>
        <RelatedEvents events={venue.otherVenues} />
      </div>
      <MobileNavigator
        className={`${styles.mobileNavigator} ${
          showNavigator ? styles.mobileNavigatorShow : ''
        }`}
      >
        <NavigatorItem scrollRef={amenitiesRef}>Amenities</NavigatorItem>
        <NavigatorItem scrollRef={descriptionRef}>Description</NavigatorItem>
        <NavigatorItem scrollRef={galleryRef}>Gallery</NavigatorItem>
      </MobileNavigator>
      <VenueHireRequest
        open={location && location.hash === '#request'}
        onClose={() => (window.location = '#')}
      />
    </div>
    </>
  )
}

const VenuePage = ({ data, location }) => {
  const venue = {
    ...data.venue.frontmatter,
    bodyHtml: data.venue.html,
    otherVenues: data.venueNodes.nodes
      .filter(n => n.fields.id !== data.venue.fields.id)
      .map(n => ({
        ...n.frontmatter,
        ...n.fields,
      })),
  }

  return <VenueTemplate location={location} venue={venue} />
}

export default VenuePage

VenuePage.propTypes = {
  data: PropTypes.object,
}

export const pageQuery = graphql`
  query VenueQuery($id: String!) {
    venueNodes: allMarkdownRemark(
      filter: { fields: { collection: { eq: "venues" } } }
    ) {
      nodes {
        fields {
          slug
          id
        }
        frontmatter {
          title  
          coverImage {
            childImageSharp {
              fluid(maxWidth: 357, maxHeight: 226, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    venue: markdownRemark(id: { eq: $id }) {
      fields {
        id
      }
      frontmatter {
        title
        meta_title
        meta_description
        descriptors
        image {
          childImageSharp {
            fluid(maxWidth: 576, maxHeight: 384, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        carousel {
          image {
            childImageSharp {
              fluid(maxWidth: 1180, maxHeight: 555, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        amenities {
          label
          image {
            childImageSharp {
              fluid(maxWidth: 90, maxHeight: 55, quality: 90, fit: CONTAIN) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
            name
          }
        }
        mosaic {
          image1 {
            childImageSharp {
              fluid(maxWidth: 460, maxHeight: 420, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            childImageSharp {
              fluid(maxWidth: 460, maxHeight: 280, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            childImageSharp {
              fluid(maxWidth: 460, maxHeight: 710, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image4 {
            childImageSharp {
              fluid(maxWidth: 460, maxHeight: 280, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image5 {
            childImageSharp {
              fluid(maxWidth: 460, maxHeight: 420, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        floorplan {
          childImageSharp {
            fluid(maxWidth: 1160, maxHeight: 700, quality: 90) {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 1160, height: 700, quality: 90) {
              ...GatsbyImageSharpFixed
            }
          }
          extension
          publicURL
        }
      }
      html
    }
  }
`
