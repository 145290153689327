import React, { useRef, useEffect } from 'react'

import styles from './mobile-navigator.module.css'

import { useScrollTopIntersection } from '../hooks/scroll'

const SCROLL_THRESHOLD = 110

const scrollToRef = ref =>
  window.scrollTo({
    top: ref.current.offsetTop - SCROLL_THRESHOLD,
    behavior: 'smooth',
  })

const MobileNavigator = ({ children, className }) => {
  const childrenTopRef = useRef()
  const intersectIndex = useScrollTopIntersection(childrenTopRef.current)

  useEffect(() => {
    childrenTopRef.current = (children || []).map(
      ({ props: { scrollRef } }) =>
        scrollRef &&
        scrollRef.current &&
        scrollRef.current.offsetTop - SCROLL_THRESHOLD - 50
    )
  }, [children])

  return (
    <div
      className={`${className} ${styles.container} w-full flex flex-row justify-between items-center`}
    >
      {children.map(({ props: { scrollRef, children: child } }, i) => (
        <span
          className={`${styles.anchorBtn} ${
            intersectIndex === i ? styles.anchorBtnActive : ''
          }`}
          key={i}
          onClick={() => scrollToRef(scrollRef)}
          tabIndex="0"
        >
          {child}
        </span>
      ))}
    </div>
  )
}

export default MobileNavigator

export const NavigatorItem = ({ scrollRef, children }) => <></>
