import { useState, useEffect } from 'react'

export function useScroll() {
  const [scrollY, setScrollY] = useState(0)
  const [scrollX, setScrollX] = useState(0)

  const listener = e => {
    const bodyOffset = window.document.body.getBoundingClientRect()
    setScrollY(-bodyOffset.top)
    setScrollX(bodyOffset.left)
  }

  useEffect(() => {
    window.addEventListener('scroll', listener)
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('scroll', listener)
      window.removeEventListener('resize', listener)
    }
  })

  return {
    scrollY,
    scrollX,
  }
}

export function useScrollTopIntersection(topArr) {
  const [intersectIndex, setIntersectIndex] = useState(-1)

  const listener = e => {
    const bodyOffset = window.document.body.getBoundingClientRect()

    let latestInd = -1

    if (topArr) {
      topArr.forEach((top, i) => {
        if (-bodyOffset.top >= top) latestInd = i
      })
    }

    if (latestInd !== intersectIndex) {
      setIntersectIndex(latestInd)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listener)
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('scroll', listener)
      window.removeEventListener('resize', listener)
    }
  })

  return intersectIndex
}
